import $axios from '~/plugins/axios';

import { Admin, AdminHierarchy, AdminHierarchyRequest, AdminRequest, AdminStatusRequest, Api } from '~/api';
import { buildModule } from '../utils';
import { Dict, InfoValueEntry, isApiValidationError, mapInfoValueEntryToRequest, removeKey } from '~/helpers';

const api = $axios as Api;

export type AdminForm = {
	firstname:string;
	lastname: string;
	username: string;
	email: string;
	phone: string | null;
	adminInfoValues: InfoValueEntry[];
	roleId: number;
	hierarchy: AdminHierarchyRequest[];
	photo: string | null;
	posId: number[];
};

export default buildModule()
	.withState({
		storedAdmins: {} as Dict<Admin>,
		validationErrors: {} as Dict<string[]>,
	})
	.withGetters({
		byId: state => (adminId: number) => (
			state.storedAdmins[adminId] ?? null
		),
		validationErrors: state => state.validationErrors,
	})
	.withMutations({
		STORE_ADMIN (state, admin: Admin) {
			state.storedAdmins = {
				...state.storedAdmins,
				[admin.id]: admin,
			};
		},
		REMOVE_STORED_ADMIN (state, id: number) {
			state.storedAdmins = removeKey(state.storedAdmins, id);
		},
		SET_VALIDATION_ERRORS (state, value: Dict<string[]>) {
			state.validationErrors = value;
		},
	})
	.withActions({
		async get ({ commit }, id: number) {
			const { data } = await api.get(`/admin/${ id }`);
			commit('STORE_ADMIN', data.payload);
		},
		async update (
			{ state, commit },
			{ id, ...form }: AdminForm & { id: number; enabled: boolean },
		) {
			const editedAdmin = state.storedAdmins[id];

			const requestInfoValues = form.adminInfoValues
				.map(mapInfoValueEntryToRequest)
				.filter((req) => req.adminInfoListItemId !== null || req.value !== null);

			const body: AdminRequest = {
				...form,
				personalData: {
					...editedAdmin.personalData,
					...form,
				},
				adminInfoValues: requestInfoValues,
			};

			commit('SET_VALIDATION_ERRORS', {});

			try {
				const { data } = await api.put(`/admin/${ id }`, body);
				commit('STORE_ADMIN', data);
			}
			catch (error) {
				if (isApiValidationError(error) && error.response.data.code === 422) {
					commit('SET_VALIDATION_ERRORS', error.response.data.context.validation);
					return;
				}

				throw error;
			}
		},
		async post ({ commit }, form: AdminForm) {

			const requestInfoValues = form.adminInfoValues
				.map(mapInfoValueEntryToRequest)
				.filter((req) => req.adminInfoListItemId !== null || req.value !== null);

			const body: AdminRequest = {
				...form,
				enabled: true,
				personalData: {
					...form,
					birthday: null,
					gender: null,
				},
				adminInfoValues: requestInfoValues,
			};

			commit('SET_VALIDATION_ERRORS', {});

			try {
				const { data } = await api.post('/admin', body);
				commit('STORE_ADMIN', data);
			}
			catch (error) {
				if (isApiValidationError(error) && error.response.data.code === 422) {
					commit('SET_VALIDATION_ERRORS', error.response.data.context.validation);
					return;
				}

				throw error;
			}
		},
		async updateState (
			{ commit },
			{ id, ...body }: { id: number } & AdminStatusRequest,
		) {
			const { data } = await api.put(`/admin/${ id }/status`, body);
			commit('STORE_ADMIN', data);
		},
		async deleteAdmin ({ commit }, id: number) {
			const { data } = await api.delete(`/admin/${ id }`);
			if (data.status === 'ok') { commit('REMOVE_STORED_ADMIN', id); }
		},
	});

export function transformAdminHierarchyToRequest (hierarchy: AdminHierarchy[]): AdminHierarchyRequest[] {
	return hierarchy.map(record => {
		return {
			regionId: record.region.id,
			areaId: record.area?.id ?? null,
			territoryId: record.territory?.id ?? null,
		};
	});
}
