import { buildModule } from '../utils';

import lazy from './lazy';
import detail from './detail';

export default buildModule()
	.withSubmodules({
		lazy,
		detail,
	});
