import { buildModule, createFiltersModule, defineSingleFilter } from '../utils';

import detail from './detail';
import history from './history';

export default buildModule()
	.withSubmodules({
		detail,
		history,
		productHistoryFilters: createFiltersModule({
			filters: {
				orderBy: defineSingleFilter('orderBy', String),
				orderAsc: defineSingleFilter('orderAsc', Boolean, false),
			},
		}),
	});
