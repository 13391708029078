import $axios from '~/plugins/axios';

import { Api, Product } from '~/api';
import { buildModule } from '../utils';
import { Dict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withState({
		storedProducts: {} as Dict<Product>,
	})
	.withGetters({
		byId: state => (productId: number): Product | null => (
			state.storedProducts[productId] ?? null
		),
	})
	.withMutations({
		STORE_PRODUCT (state, product: Product) {
			state.storedProducts = {
				...state.storedProducts,
				[product.id]: product,
			};
		},
	})
	.withActions({
		async get ({ commit }, id: number) {
			const { data } = await api.get(`/products/${ id }`);
			commit('STORE_PRODUCT', data.payload);
		},
	});
