import flatpickr from 'flatpickr';
import { CustomLocale } from 'flatpickr/dist/types/locale';
import { Czech } from 'flatpickr/dist/l10n/cs';
import { Slovak } from 'flatpickr/dist/l10n/sk';
import { english } from 'flatpickr/dist/l10n/default';
import { Dict } from '~/helpers';
import moment from '~/plugins/moment';

const LOCALES: Dict<CustomLocale> & Record<typeof DEFAULT_LOCALE, CustomLocale> = {
	cs: Czech,
	sk: Slovak,
	en: {
		...english,
		weekdays: {
			...english.weekdays,
			shorthand: [ 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su' ],
		},
	},
};

const DEFAULT_LOCALE = 'cs';

flatpickr.localize(LOCALES[document.documentElement.lang] ?? LOCALES[DEFAULT_LOCALE]);

// Inicializace kalendare
export const flatpickrConfig: flatpickr.Options.Options = {
	dateFormat: 'YYYY-MM-DD',
	altFormat: 'D. M. YYYY',
	altInput: true,
	monthSelectorType: 'static',
	disableMobile: true,
	parseDate: (datestr, format) => {
		return moment(datestr, format, true).toDate();
	},
	formatDate: (date, format) => {
		return moment(date).format(format);
	},
};

export function initFlatpickr (input: Element): void {
	flatpickr(input, flatpickrConfig);
}
