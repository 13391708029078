import $axios from '~/plugins/axios';

import { Api, Pos } from '~/api';
import { buildModule } from '../utils';
import { Dict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withState({
		storedPoses: {} as Dict<Pos>,
	})
	.withGetters({
		byId: state => (posId: number): Pos | null => (
			state.storedPoses[posId] ?? null
		),
	})
	.withMutations({
		STORE_POS (state, pos: Pos) {
			state.storedPoses = {
				...state.storedPoses,
				[pos.id]: pos,
			};
		},
	})
	.withActions({
		async get ({ commit }, id: number) {
			const { data } = await api.get(`/pos/${ id }`);
			commit('STORE_POS', data.payload);
		},
	});
