import { RouteDefinition } from './types';

export default [
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('~/views/ErrorPage.vue'),
		props: { statusCode: 404 },
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import('~/views/Dashboard.vue'),
		meta: {
			redirectToChild: true,
		},
		children: [
			{
				path: '/dashboard/audits',
				name: 'DashboardAudits',
				component: () => import('~/views/DashboardAudits.vue'),
				meta: {
					permissions: [ {
						subject: 'Dashboard',
						action: 'audit',
					} ],
				},
			},
			{
				path: '/dashboard/planner',
				name: 'DashboardPlanner',
				component: () => import('~/views/DashboardPlanner.vue'),
				meta: {
					permissions: [ {
						subject: 'Dashboard',
						action: 'planner',
					} ],
				},
			},
		],
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import('~/views/Profile.vue'),
		meta: {
			redirectToChild: true,
		},
		children: [
			{
				path: '/profile/detail',
				name: 'ProfileDetail',
				component: () => import('~/views/ProfileDetail.vue'),
				meta: {
					permissions: [
						{
							subject: 'Profile',
							action: 'all',
						},
					],
				},
			},
			{
				path: '/profile/settings',
				name: 'ProfileSettings',
				component: () => import('~/views/ProfileSettings.vue'),
				meta: {
					forbiddenAsAnotherAdmin: true,
				},
			},
			{
				path: '/profile/change-password',
				name: 'ProfilePassword',
				component: () => import('~/views/ProfilePasswordChange.vue'),
				meta: {
					forbiddenAsAnotherAdmin: true,
				},
			},
			{
				path: '/profile/edit',
				name: 'ProfileEditation',
				component: () => import('~/views/ProfileEditation.vue'),
				meta: {
					permissions: [
						{
							subject: 'Profile',
							action: 'edit',
						},
					],
					forbiddenAsAnotherAdmin: true,
				},
			},
		],
	},
	{
		path: '/planner',
		name: 'Planner',
		component: () => import('~/views/Planner.vue'),
		meta: {
			redirectToChild: true,
		},
		children: [
			{
				path: '/planner/my-calendar',
				name: 'PlannerCalendar',
				component: () => import('~/views/PlannerCalendar.vue'),
				meta: {
					permissions: [ {
						subject: 'Planner',
						action: 'all',
					} ],
				},
			},
			{
				path: '/planner/planning',
				name: 'PlannerPlanning',
				component: () => import('~/views/PlannerPlanning.vue'),
				meta: {
					redirectToChild: true,
					permissions: [ {
						subject: 'Planner',
						action: 'schedule',
					}, {
						subject: 'Planner',
						action: 'schedulePreview',
					} ],
				},
				children: [
					{
						path: '/planner/planning/poses',
						name: 'PlannerPlanningPoses',
						component: () => import('~/views/PlannerPlanningPoses.vue'),
						meta: {
							permissions: [ {
								subject: 'Planner',
								action: 'schedule',
							}, {
								subject: 'Planner',
								action: 'schedulePreview',
							} ],
						},
					},
					{
						path: '/planner/planning/users',
						name: 'PlannerPlanningUsers',
						component: () => import('~/views/PlannerPlanningUsers.vue'),
						meta: {
							permissions: [ {
								subject: 'Planner',
								action: 'schedule',
							}, {
								subject: 'Planner',
								action: 'schedulePreview',
							} ],
						},
					},
				],
			},
			{
				path: '/planner/activities',
				name: 'PlannerActivities',
				component: () => import('~/views/PlannerActivities.vue'),
				meta: {
					permissions: [ {
						subject: 'Planner',
						action: 'schedule',
					}, {
						subject: 'Planner',
						action: 'schedulePreview',
					} ],
				},
			},
			{
				path: '/planner/holidays',
				name: 'PlannerHolidays',
				component: () => import('~/views/PlannerHolidays.vue'),
				meta: {
					permissions: [ {
						subject: 'Planner',
						action: 'settings',
					} ],
				},
			},
		],
	},
	{
		path: '/core/notification',
		name: 'Notifications',
		component: () => import('~/views/Notifications.vue'),
	},
	{
		path: '/settings/planner',
		name: 'SettingsPlanner',
		component: () => import('~/views/SettingsPlanner.vue'),
	},
	{
		path: '/settings/hierarchy',
		name: 'SettingsHierarchy',
		component: () => import('~/views/SettingsHierarchy.vue'),
	},
	{
		path: '/settings/employees',
		name: 'SettingsEmployees',
		component: () => import('~/views/SettingsEmployees.vue'),
	},
	{
		path: '/settings/audits',
		name: 'SettingsAudits',
		component: () => import('~/views/SettingsAudits.vue'),
	},
	{
		path: '/settings/audit-categories',
		name: 'SettingsAuditCategories',
		component: () => import('~/views/SettingsAuditCategories.vue'),
	},
	{
		path: '/settings/dashboard',
		name: 'SettingsDashboard',
		component: () => import('~/views/SettingsDashboard.vue'),
	},
	{
		path: '/settings/protocol',
		name: 'SettingsProtocol',
		component: () => import('~/views/SettingsProtocol.vue'),
	},
	{
		path: '/audit',
		name: 'Audits',
		component: () => import('~/views/Audits.vue'),
		meta: {
			redirectToChild: true,
		},
		children: [
			{
				path: '/audit/audits',
				name: 'AuditsList',
				component: () => import('~/views/AuditsList.vue'),
				meta: {
					permissions: [ {
						subject: 'Audit',
						action: 'all',
					} ],
				},
			},
			{
				path: '/audit/results',
				name: 'AuditsResults',
				component: () => import('~/views/AuditsResults.vue'),
				meta: {
					redirectToChild: true,
				},
				children: [
					{
						path: '/audit/results/poses',
						name: 'AuditsResultsPoses',
						component: () => import('~/views/AuditsResultsPoses.vue'),
						meta: {
							permissions: [ {
								subject: 'Audit',
								action: 'all',
							} ],
						},
					},
					{
						path: '/audit/results/users',
						name: 'AuditsResultsUsers',
						component: () => import('~/views/AuditsResultsUsers.vue'),
						meta: {
							permissions: [ {
								subject: 'Audit',
								action: 'all',
							} ],
						},
					},
				],
			},

		],
	},
	{
		path: '/admins',
		name: 'Admins',
		component: () => import('~/views/Admins.vue'),
		meta: {
			redirectToChild: true,
		},
		children: [
			{
				path: '/admins/list',
				name: 'AdminsList',
				component: () => import('~/views/AdminsList.vue'),
				meta: {
					permissions: [ {
						subject: 'Admins',
						action: 'all',
					} ],
				},
			},
			{
				path: '/admins/detail/:adminId',
				name: 'AdminsDetail',
				component: () => import('~/views/AdminsDetail.vue'),
				meta: {
					permissions: [ {
						subject: 'Admins',
						action: 'all',
					}, {
						subject: 'Admins',
						action: 'edit',
					} ],
				},
			},
			{
				path: '/admins/add',
				name: 'AdminsAddition',
				component: () => import('~/views/AdminsAddition.vue'),
				meta: {
					permissions: [ {
						subject: 'Admins',
						action: 'add',
					} ],
				},
			},
		],
	},
	{
		path: '/library',
		name: 'Library',
		component: () => import('~/views/Library.vue'),
		meta: {
			redirectToChild: true,
		},
		children: [
			{
				path: '/library/information',
				name: 'LibraryInformation',
				component: () => import('~/views/LibraryInformation.vue'),
				meta: {
					permissions: [ {
						subject: 'Library',
						action: 'information',
					} ],
				},
			},
			{
				path: '/library/folders',
				name: 'LibraryFolderNotSelected',
				component: () => import('~/views/LibraryFolderNotSelected.vue'),
				meta: {
					permissions: [ {
						subject: 'Library',
						action: 'all',
					} ],
				},
			},
			{
				path: '/library/:folderId',
				name: 'LibraryFolder',
				component: () => import('~/views/LibraryFolder.vue'),
				meta: {
					permissions: [ {
						subject: 'Library',
						action: 'all',
					} ],
				},
			},
		],
	},
	{
		path: '/task',
		name: 'Tasks',
		component: () => import('~/views/Tasks.vue'),
		meta: {
			redirectToChild: true,
		},
		children: [
			{
				path: '/task/items/list',
				name: 'TaskItemsList',
				component: () => import('~/views/TasksItemsList.vue'),
				meta: {
					permissions: [ {
						subject: 'Task',
						action: 'all',
					} ],
				},
			},
			{
				path: '/task/list/recurring',
				name: 'TasksList',
				component: () => import('~/views/TasksList.vue'),
				meta: {
					permissions: [ {
						subject: 'Task',
						action: 'all',
					} ],
				},
			},
			{
				path: '/task/detail',
				name: 'TasksDetail',
				component: () => import('~/views/TasksDetail.vue'),
				meta: {
					permissions: [ {
						subject: 'Task',
						action: 'edit',
					} ],
				},
			},
			{
				path: '/task/task-types',
				name: 'TasksTaskTypes',
				component: () => import('~/views/TasksTaskTypes.vue'),
				meta: {
					permissions: [ {
						subject: 'Task',
						action: 'settings',
					} ],
				},
			},
		],
	},
	{
		path: '/store/product-serial-numbers',
		name: 'ProductHistory',
		component: () => import('~/views/ProductHistory.vue'),
	},
	{
		path: '/export/audit',
		name: 'ExportsAudits',
		component: () => import('~/views/ExportsAudits.vue'),
	},
] satisfies RouteDefinition[];
