/* eslint-disable no-var,prefer-const */

import $axios from '../../plugins/axios';
import $moment from '../../plugins/moment';

import { fetchAllPages } from '~/helpers';
import { Admin, AdminsResponse, Api, Area, Availability, Event, EventRequest, EventType, Excuse, Filter, GetAdminParams, GetAreaParams, GetEventTypeParams, GetEventsParams, GetExcusesParams, GetFilterParams, GetPlannerHolidayParams, GetPosParams, GetRegionParams, GetRoleParams, GetSettingPosTypeParams, GetTerritoryParams, PaginatorResult, PlannerHoliday, Pos, PosType, PosesResponse, Region, RoleLight, Territory } from '~/api';
import { createFiltersModule, defineSingleFilter, defineArrayFilter, buildModule } from '../utils';

const api = $axios as Api;

export type ConflictDate = {
	date: string;
	admins: (Availability & { name: string })[];
};

const ACTIVITY_DEFAULT = {
	start: '',
	end: '',
	startTime: '07:00',
	endTime: '07:00',
	status: 'booked',
	note: null,
	pos: 0,
	admins: [],
	eventType: null,
	role: null,
};

const COMMON_FILTERS = {
	searchTerm: defineSingleFilter('search', String),
	areaId: defineSingleFilter('area', Number),
	regionId: defineSingleFilter('region', Number),
	territoryId: defineSingleFilter('territory', Number),
	plannedPeriod: defineSingleFilter('plannedPeriod', String),
	customFilterIds: defineArrayFilter('filterId', Number),
	customFilterValues: defineArrayFilter('filterValue', String),
	eventType: defineArrayFilter('eventType', Number),
	plannedFrom: defineSingleFilter('plannedFrom', String),
	plannedTo: defineSingleFilter('plannedTo', String),
	note: defineSingleFilter('note', String),
};

export default buildModule()
	.withSubmodules({
		posFilters: createFiltersModule({
			filters: {
				...COMMON_FILTERS,
				posTypeId: defineArrayFilter('posType', Number),
			},
			replaceRoute: true,
		}),
		adminFilters: createFiltersModule({
			filters: {
				...COMMON_FILTERS,
				roleId: defineArrayFilter('role', Number),
			},
			replaceRoute: true,
		}),
	})
	.withState({
		plannablePoses: null as PosesResponse | null,
		plannableAdmins: null as AdminsResponse | null,

		poses: [] as Pos[],
		posTypes: [] as PosType[],
		posTypesSelected: [] as PosType[],
		users: [] as Admin[],
		roles: [] as RoleLight[],
		dates: [] as string[],
		datesClone: [] as string[],
		activitiesAll: [] as Event[],
		activityTypes: [] as EventType[],

		// TODO consider refactoring these (partially) to local state, otherwise type here properly
		activityForm: null as any, // eslint-disable-line @typescript-eslint/no-explicit-any
		activityFormClone: null as any, // eslint-disable-line @typescript-eslint/no-explicit-any

		activityPos: null,
		activityAdmins: [],
		activityRole: null,
		activityEventType: null,

		checkingAvailability: false,
		conflictDates: [] as ConflictDate[],
		isEventConflict: false,

		holidays: [] as PlannerHoliday[],
		excuses: [] as Excuse[],

		typesByRole: [],
		usersByRole: [],

		eventsLoading: false,

		areas: [] as Area[],
		regions: [] as Region[],
		territory: [] as Territory[],
		regionPaginator: null as PaginatorResult | null,
		areaPaginator: null as PaginatorResult | null,
		territoryPaginator: null as PaginatorResult | null,

		customFilters: [] as Filter[],
		customFiltersLoaded: false,
	})
	.withGetters({
		plannablePoses: state => state.plannablePoses?.payload ?? [],
		plannablePosesTotalCount: state => state.plannablePoses?.paginator.totalCount ?? 0,
		plannableAdmins: state => state.plannableAdmins?.payload ?? [],
		plannableAdminsTotalCount: state => state.plannableAdmins?.paginator.totalCount ?? 0,

		conflictDates: state => state.conflictDates,
		adminConflictDatesById: state => (adminId: number) => {
			return state.conflictDates.filter(date => {
				return date.admins.some(admin => admin.adminId === adminId);
			}).map(date => date.date);
		},
		userEvents: state => (userId: number) => {
			return state.activitiesAll.filter(activity => activity.admins.some(admin => admin.id === userId));
		},
	})
	.withGetters({
		conflictingEvents: (_, getters) => (adminId: number, start: string, end: string) => {
			const plannedRange = $moment.range($moment(start), $moment(end));
			return getters.userEvents(adminId).filter(event => {
				const eventRange = $moment.range($moment(event.start), $moment(event.end));
				return eventRange.overlaps(plannedRange);
			});
		},
		poses: state => state.poses,
		posesAvailable: (state, getters) => {
			return state.poses.map(pos => {
				// @ts-expect-error TODO refactor this
				pos.$isDisabled = getters.posDisabled(pos);
				return pos;
			});
		},
		posDisabled: (state, getters) => (pos: Pos) => {
			// @ts-expect-error TODO reorder / refactor
			const selectedHolidays: PlannerHoliday[] = getters.selectedHolidays;
			const selectedRole = state.activityForm.role;

			return selectedHolidays.some(holiday => {
				// holiday pro vsechny posy
				const forAllPoses = holiday.poses.length === 0;
				// holiday pro nektere posy (a ty mame v nabidce)
				const forVisiblePos = holiday.poses.some(holidayPos => holidayPos.id === pos.id);

				// holiday pro vsechny role
				const forAllRoles = holiday.roles.length === 0;
				// holiday ne pro vsechny role, ale pro zrovna vybranou ano (a nejakou vybranou mame)
				const forSelectedRole = !forAllRoles && (!holiday.roles.some(role => role.id === selectedRole) && selectedRole);

				return (forAllPoses || forVisiblePos) && (forAllRoles || forSelectedRole);
			});
		},
		users: state => state.users,

		regionTotalCount: state => state.regionPaginator?.totalCount ?? 0,

		posTypes: state => state.posTypes,
		posTypesSelected: state => state.posTypesSelected,
		roles: state => state.roles,
	})
	.withGetters({
		rolesDisabled: (state, getters) => {
			// @ts-expect-error TODO reorder / refactor
			const selectedHolidays: PlannerHoliday[] = getters.selectedHolidays;
			const selectedPos = state.activityForm.pos;
			const disabledRoles = getters.roles.filter(role => {
				return selectedHolidays.some(holiday => {
					// holiday omezeny na pos, ale neni vybrany pos -> planovatelny
					if (holiday.poses.length && !selectedPos) {
						return false;
					}
					// holiday omezeny na pos, pos je vybrany, ale tento holiday pro nej neni
					if (holiday.poses.length && selectedPos && !holiday.poses.some(pos => pos.id === selectedPos.id)) {
						return false;
					}
					return !holiday.roles.some(holidayRole => holidayRole.id === role.id);
				});
			});
			return disabledRoles;
		},
		dates: state => state.dates,
	})
	.withGetters({
		activitiesAll: state => state.activitiesAll,
		activitiesLoading: state => state.eventsLoading,
		activityForm: state => state.activityForm,
		selectedUserNameById: state => (id: number) => {
			// @ts-expect-error TODO consider refactoring to local state, otherwise type here properly
			const user = state.activityForm?.admins.find(admin => admin.id === id);
			if (!user) {
				return;
			}
			return user.personalData.fullname;
		},

		resourceItemsIdsByExcludedRoles: state => (roleIds: number[]) => {
			// @ts-expect-error TODO refactor this after splitting the joint planning view
			return state.resourceItems.filter(resource => !roleIds.includes(resource.role?.id)).map(resource => resource.id);
		},
	})
	.withGetters({
		holidays: state => state.holidays.map(holiday => {
			return holiday;
		}),

		selectedHolidays: (state, getters) => {
			// TODO: pridat casy start-end
			const selected = getters.dates;
			return state.holidays.filter(holiday => {
				return selected.some(selectedDate => {
					return $moment(selectedDate).isBetween(holiday.start, holiday.end, 'minute', '[]');
				});
			});
		},

		filteredHolidays: state => ({ roleId = '', posId = '' }: {
			roleId?: string;
			posId?: string;
		}) => {
			const hasPosFilter = posId || parseInt(posId) === 0;
			const hasRoleFilter = roleId || parseInt(roleId) === 0;

			return state.holidays.filter(holiday => {
				const forAllPoses = holiday.poses.length === 0;
				const forAllRoles = holiday.roles.length === 0;

				// globalni nepracovni den, plati pro vsechny vsude
				if (forAllPoses && forAllRoles) {
					return true;
				}

				// pokud je pro vsechny posy, nezalezi na vyberu, jinak hledame, jestli holiday plati pro vybrany pos
				const forThisPos = forAllPoses || (holiday.poses.some(pos => pos.id === parseInt(posId)) && hasPosFilter);
				if (!forThisPos) {
					return false;
				}

				// pokud plati pro tento (nebo vsechny) posy, pak kontrolujeme, jestli je bud bez vyjimek pro pos, nebo nema vybrana role vyjimku
				const forThisRole = forAllRoles || ((!holiday.roles.some(role => role.id === parseInt(roleId)) && hasRoleFilter));
				return forThisRole;
			});
		},

		excuses: state => state.excuses,

		usersByRole: state => state.usersByRole,
		typesByRole: state => state.typesByRole,

		activityTypes: state => state.activityTypes,

		areas: state => state.areas,
		regions: state => state.regions,
		territory: state => state.territory,

		posCustomFilters: state => state.customFilters.filter(f => f.infoType === 'pos'),
		adminCustomFilters: state => state.customFilters.filter(f => f.infoType === 'admin'),
	})
	.withMutations({
		EVENTS_LOADING (state, isLoading: boolean) {
			state.eventsLoading = isLoading;
		},

		ACTIVITIES_SUCCESS (state, activities: Event[]) {
			state.activitiesAll = activities;
		},

		HOLIDAYS_SUCCESS (state, holidays: PlannerHoliday[]) {
			state.holidays = holidays;
		},

		EXCUSES_SUCCESS (state, excuses: Excuse[]) {
			state.excuses = excuses;
		},

		PLANNABLE_POSES_SUCCESS (state, response: PosesResponse) {
			state.plannablePoses = response;
		},

		PLANNABLE_ADMINS_SUCCESS (state, response: AdminsResponse) {
			state.plannableAdmins = response;
		},

		REGIONS_PAGINATION (state, paginator: PaginatorResult) {
			state.regionPaginator = paginator;
		},

		POSES_SUCCESS (state, poses: Pos[]) {
			state.poses = poses;
		},

		POS_TYPES_SUCCESS (state, posTypes: PosType[]) {
			state.posTypes = posTypes;
		},

		POS_TYPES_SELECTED (state, posTypes: PosType[]) {
			state.posTypesSelected = posTypes;
		},

		USERS_SUCCESS (state, users: Admin[]) {
			state.users = users;
		},

		ROLES_SUCCESS (state, roles: RoleLight[]) {
			state.roles = roles;
		},

		TYPES_SUCCESS (state, types: EventType[]) {
			state.activityTypes = types;
		},

		ADD_ACTIVITY: (state, activity: Event) => {
			state.activitiesAll.push(activity);
		},

		ADD_ACTIVITY_NEW: (state, { start, end, pos, admins, startTime, endTime }: {
			start: string;
			end: string;
			pos: Pos | null;
			admins: Admin[];
			startTime: string;
			endTime: string;
		}) => {
			state.activityEventType = null;

			state.activityForm = Object.assign({}, ACTIVITY_DEFAULT);
			state.activityFormClone = Object.assign({}, ACTIVITY_DEFAULT);

			state.activityForm.start = state.activityFormClone.start = start;
			state.activityForm.end = state.activityFormClone.end = end;
			state.activityForm.pos = state.activityFormClone.pos = pos;
			state.activityForm.admins = state.activityFormClone.admins = admins;
			state.activityForm.startTime = state.activityFormClone.startTime = startTime;
			state.activityForm.endTime = state.activityFormClone.endTime = endTime;
		},

		SHOW_ACTIVITY: (state, eventId: number) => {
			state.activityPos = state.activityEventType = null;
			state.activityAdmins = [];

			const thisActivity = state.activitiesAll.find(event => { return event.id === eventId; });
			// TODO: better cloning
			state.activityForm = JSON.parse(JSON.stringify(thisActivity));
			state.activityFormClone = JSON.parse(JSON.stringify(thisActivity));

			state.activityForm.role = state.activityFormClone.role = state.activityForm.role ? state.activityForm.role.id : null;
		},

		DELETE_ACTIVITY_NEW: (state) => {
			state.usersByRole = [];
			state.typesByRole = [];
			// state.dates = [];
			state.activityForm = null;
		},

		SET_ACTIVITY_ROLE: (state, { roleId, isUsers }: {
			roleId: number;
			isUsers: boolean;
		}) => {
			if (state.activityForm) { state.activityForm.role = roleId; }

			// Clear admins if no longer fit the role
			if (!isUsers && (state.activityForm && state.activityForm.admins.length)) {
				// @ts-expect-error TODO refactor this to planning popup's local state mgmt
				state.activityForm.admins = state.activityForm.admins.filter(admin => {
					// @ts-expect-error TODO refactor this to planning popup's local state mgmt
					return state.usersByRole.map(usr => usr.id).includes(admin.id);
				});
			}
		},

		SET_ACTIVITY_TYPE (state, type: number) {
			state.activityForm.eventType = type;
		},

		SET_ACTIVITY_POS (state, posId: number) {
			state.activityForm.pos = posId;
		},

		SET_ACTIVITY_START_TIME (state, value: string) {
			state.activityForm.startTime = value;
		},

		SET_ACTIVITY_END_TIME (state, value: string) {
			state.activityForm.endTime = value;
		},

		SET_ACTIVITY_USER (state, user: Admin[]) {
			state.activityForm.admins = user;
		},

		SET_ACTIVITY_DATES (state, dates: string | string[]) {
			state.dates = typeof dates === 'string' ? dates.split(', ') : dates;
		},

		SET_ACTIVITY_NOTE (state, note: string | null) {
			state.activityForm.note = note;
		},

		AREAS_SUCCESS (state, areas: Area[]) {
			state.areas = areas;
		},

		AREA_PAGINATOR (state, paginator: PaginatorResult) {
			state.areaPaginator = paginator;
		},

		ADD_AREAS (state, areas: Area[]) {
			state.areas = state.areas.concat(areas);
		},

		REGIONS_SUCCESS (state, regions: Region[]) {
			state.regions = regions;
		},

		TERRITORY_SUCCESS (state, territory: Territory[]) {
			state.territory = territory;
		},

		TERRITORY_PAGINATOR (state, paginator: PaginatorResult) {
			state.territoryPaginator = paginator;
		},

		ADD_TERRITORIES (state, territory: Territory[]) {
			state.territory = state.territory.concat(territory);
		},

		CONFLICT_DATES (state, availability: ConflictDate[]) {
			state.conflictDates = availability;
		},

		IS_EVENT_CONFLICT (state, availability: boolean) {
			state.isEventConflict = availability;
		},

		CHECKING_AVAILABILITY (state, value: boolean) {
			state.checkingAvailability = value;
		},

		CUSTOM_FILTERS_SUCCESS (state, value: Filter[]) {
			state.customFilters = value;
			state.customFiltersLoaded = true;
		},
	})
	.withActions({
		clearActivity ({ state, commit }) {
			state.dates = [];

			let daysBetween = $moment(state.activityFormClone.end).diff($moment(state.activityFormClone.start), 'days');
			for (var i = 0; i <= daysBetween; i++) {
				let date = $moment(state.activityFormClone.start).add(i, 'days').format('YYYY-MM-DD');
				state.dates.push(date);
			}

			state.activityForm = JSON.parse(JSON.stringify(state.activityFormClone));

			if (!state.activityFormClone.id) {
				commit('SET_ACTIVITY_ROLE', {
					roleId: state.activityForm.admins[0]?.role.id || state.activityForm.admins[0]?.role,
					isUsers: true,
				});
			}
		},

		clearActivityNew ({ state, commit }) {
			state.dates = [];
			let start = state.activityForm.start;
			let end = state.activityForm.end;

			let daysBetween = $moment(state.activityForm.end).diff($moment(state.activityForm.start), 'days');
			for (var i = 0; i <= daysBetween; i++) {
				let date = $moment(state.activityForm.start).add(i, 'days').format('YYYY-MM-DD');
				state.dates.push(date);
			}

			state.activityForm = Object.assign({}, ACTIVITY_DEFAULT);

			state.activityForm.eventType = state.activityEventType;
			state.activityForm.admins = state.activityAdmins;
			commit('SET_ACTIVITY_ROLE', {
				roleId: state.activityForm.admins[0]?.role.id || state.activityForm.admins[0]?.role,
				isUsers: true,
			});

			state.activityForm.pos = state.activityPos;

			state.activityForm.start = start;
			state.activityForm.end = end;
		},

		async getActivitiesAll ({ commit }, params: GetEventsParams = {}) {
			commit('EVENTS_LOADING', true);

			const events = await fetchAllPages('/events', params)<Event>(api);

			commit('ACTIVITIES_SUCCESS', events);
			commit('EVENTS_LOADING', false);
		},

		async getHolidays ({ commit }, params: GetPlannerHolidayParams = {}) {
			const holidays = await fetchAllPages('/planner-holiday', params)<PlannerHoliday>(api);
			commit('HOLIDAYS_SUCCESS', holidays);
		},

		async getExcuses ({ commit }, params: GetExcusesParams = {}) {
			const extendedParams: GetExcusesParams = {
				...params,
				status: [ 'accepted', 'waiting' ],
			};

			const excuses = await fetchAllPages('/excuses', extendedParams)<Excuse>(api);
			commit('EXCUSES_SUCCESS', excuses);
		},

		async getPlannablePoses ({ commit }, params: GetPosParams = {}) {
			const { data } = await api.get('/pos', { params });
			commit('PLANNABLE_POSES_SUCCESS', data);
		},

		async getPlannableAdmins ({ commit }, params: GetAdminParams = {}) {
			const { data } = await api.get('/admin', { params });
			commit('PLANNABLE_ADMINS_SUCCESS', data);
		},

		async getPoses ({ commit }, params: GetPosParams = {}) {
			const { data } = await api.get('/pos', { params: params });
			commit('POSES_SUCCESS', data.payload);
		},

		async getUsers ({ commit }, params: GetAdminParams = {}) {
			const { data } = await api.get('/admin', { params: { ...params, withMe: params.withMe ?? true } });
			commit('USERS_SUCCESS', data.payload);
		},

		checkUsersPlanningAvailability ({ commit, getters }, { eventId, excuseId, userIds, dates, isAllDay = false, startTime, endTime, posId }: {
			eventId: number;
			excuseId: number;
			userIds: number[];
			dates: string[];
			isAllDay?: boolean;
			startTime: string;
			endTime: string;
			posId: number;
		}) {
			if (!isAllDay && $moment(startTime, 'HH:mm').isSameOrAfter($moment(endTime, 'HH:mm'))) {
				// zbytecne nevalidovat, kdyz startTime >= endTime
				commit('CONFLICT_DATES', []);
				return;
			}
			if (userIds.length === 0) {
				// zbytecne nevalidovat, kdyz nemame uzivatele
				commit('CONFLICT_DATES', []);
				return;
			}
			commit('CHECKING_AVAILABILITY', true);
			const startEndList = dates.map(date => {
				let endDate = date;
				if (isAllDay) {
					endDate = $moment(date).add(1, 'd').format('YYYY-MM-DD');
					startTime = '00:00';
					endTime = '00:00';
				}

				return {
					start: $moment(`${ date }T${ startTime }`).format(),
					end: $moment(`${ endDate }T${ endTime }`).format(),
					admin: userIds,
					event: eventId,
					excuseRequest: excuseId,
					posId: posId,
				};
			});

			const promisesList = startEndList.map(startEnd => (
				api.get('/availability', { params: startEnd })
			));

			return Promise.all(promisesList).then(responses => {
				const allConflictDates = responses.map((wut, index) => {
					return { date: dates[index],
						admins: wut.data.payload.filter(admin => !admin.available).map(admin => {
							return { ...admin, name: getters.selectedUserNameById(admin.adminId) };
						}) };
				});
				commit('CONFLICT_DATES', allConflictDates.filter(date => date.admins.length));
			}).finally(() => {
				commit('CHECKING_AVAILABILITY', false);
			});
		},

		checkUsersAvailabilitySingle ({ commit }, { eventId, excuseId, userId, start, end, posId }: {
			eventId: number;
			excuseId: number;
			userId: number;
			start: string;
			end: string;
			posId: number;
		}) {
			if ($moment(start).isSameOrAfter(end)) {
				// zbytecne nevalidovat, kdyz startTime >= endTime
				commit('IS_EVENT_CONFLICT', false);
				return;
			}
			if (userId === null) {
				// zbytecne nevalidovat, kdyz nemame uzivatele
				commit('IS_EVENT_CONFLICT', false);
				return;
			}

			api.get('/availability', { params: {
				start: start,
				end: end,
				admin: [ userId ],
				event: eventId,
				excuseRequest: excuseId,
				posId: posId,
			} })
				.then(response => {
					commit('IS_EVENT_CONFLICT', response.data.payload.some(availability => availability.adminId === userId && !availability.available));
				});
		},

		getActivityTypes ({ commit }, params: GetEventTypeParams) {
			return api.get('/event-type', { params: params })
				.then(resp => {
					const types = resp.data.payload;
					commit('TYPES_SUCCESS', types);
				})
				.catch(err => { console.log(err); });
		},

		getRoles ({ commit }, customParams: GetRoleParams = {}) {
			const params = { ...customParams, withMe: true, isInPlanner: true };

			return api.get('/role', { params })
				.then(resp => {
					const roles = resp.data.payload;
					commit('ROLES_SUCCESS', roles);
				})
				.catch(err => { console.log(err); });
		},

		async getPosTypes ({ commit }, params: GetSettingPosTypeParams = {}) {
			params = { orderBy: 'name', orderAsc: true, ...params };
			const { data } = await api.get('/setting/pos-type', { params: params });

			commit('POS_TYPES_SUCCESS', data.payload);
		},

		async getPosTypesSelected ({ commit }, params: GetSettingPosTypeParams = { id: [] }) {
			params = { orderBy: 'name', orderAsc: true, ...params };
			const { data } = await api.get('/setting/pos-type', { params: params });

			commit('POS_TYPES_SELECTED', data.payload);
		},
	})
	.withActions({
		addActivity (_, activity: EventRequest) {
			return api.post('/events', activity)
				.then(resp => resp)
				.catch((err) => err);
		},

		async editActivity (_, { id, ...activity }: { id: number } & EventRequest) {
			await api.put(`/events/${ id }`, activity);
		},

		async deleteActivity (_, activityId: number) {
			await api.delete(`/events/${ activityId }`);
			// commit('DELETE_ACTIVITY', activityId);
		},

		setPopup ({ state, commit, dispatch }, name: 'users' | 'poses') {
			Promise.all([
				// dispatch('getUsers', { roleId: state.activityForm.role ? [ state.activityForm.role ] : [], posId: state.activityForm.pos?.id || null }),
				// dispatch('getPoses'),
				dispatch('getRoles'),
				dispatch('getActivityTypes', { roleId: state.activityForm.role }),
			])
				.finally(() => {
					if (name === 'users' && !state.activityForm.id) {
						commit('SET_ACTIVITY_ROLE', {
							roleId: state.activityForm.admins[0]?.role.id || state.activityForm.admins[0]?.role,
							isUsers: true,
						});
					}
				});
		},

		getArea ({ commit }, params: GetAreaParams = {}) {
			return api.get('/area', { params: params })
				.then(resp => {
					const areas = resp.data.payload;
					const paginator = resp.data.paginator;

					if (params.offset) { commit('ADD_AREAS', areas); commit('AREA_PAGINATOR', paginator); }
					else { commit('AREAS_SUCCESS', areas); commit('AREA_PAGINATOR', paginator); }
				})
				.catch(err => { console.log(err); });
		},

		getRegion ({ commit }, params: GetRegionParams = {}) {
			return api.get('/region', { params: params })
				.then(resp => {
					const regions = resp.data.payload;
					const paginator = resp.data.paginator;
					commit('REGIONS_SUCCESS', regions);
					commit('REGIONS_PAGINATION', paginator);
				})
				.catch(err => { console.log(err); });
		},

		getTerritory ({ commit }, params: GetTerritoryParams = {}) {
			return api.get('/territory', { params: params })
				.then(resp => {
					const territory = resp.data.payload;
					const paginator = resp.data.paginator;

					if (params.offset) { commit('ADD_TERRITORIES', territory); commit('TERRITORY_PAGINATOR', paginator); }
					else { commit('TERRITORY_SUCCESS', territory); commit('TERRITORY_PAGINATOR', paginator); }
				})
				.catch(err => { console.log(err); });
		},
	})
	.withActions({
		getFilters ({ dispatch }) {
			dispatch('getArea');
			dispatch('getRegion');
			dispatch('getTerritory');
		},

		getCustomFilters ({ commit }, planningType: 'poses' | 'users') {
			const params: GetFilterParams = {
				key: 'planner',
			};
			if (planningType === 'poses') {
				params.hasPosInfo = true;
			}
			if (planningType === 'users') {
				params.hasAdminInfo = true;
			}

			return api.get('/filter', { params: params })
				.then(resp => {
					const customFilters = resp.data.payload;
					commit('CUSTOM_FILTERS_SUCCESS', customFilters);
					return resp;
				});
		},
	});
