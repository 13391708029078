import $axios from '~/plugins/axios';

import { Api, GetProductHistoryIdParams, PaginatorResult, ProductHistory } from '~/api';
import { buildModule, createPaginationModule } from '../utils';

const DEFAULT_HISTORY_PAGE_SIZE = 10;

const api = $axios as Api;

export default buildModule()
	.withSubmodules({
		pagination: createPaginationModule({
			itemCountGetter: 'products/history/totalCount',
			defaultPageSize: DEFAULT_HISTORY_PAGE_SIZE,
		}),
	})
	.withState({
		productHistories: [] as ProductHistory[],
		loading: false,
		paginator: null as PaginatorResult | null,
	})
	.withGetters({
		page: state => state.productHistories,
		loading: state => state.loading,
		totalCount: state => state.paginator?.totalCount ?? 0,
	})
	.withMutations({
		SET_PRODUCT_HISTORIES (state, productHistories: ProductHistory[]) {
			state.productHistories = productHistories;
		},
		SET_LOADING (state, loading: boolean) {
			state.loading = loading;
		},
		SET_PAGINATOR (state, paginator: PaginatorResult) {
			state.paginator = paginator;
		},
	})
	.withActions({
		async get ({ commit }, { id, ...params }: { id: number } & GetProductHistoryIdParams) {
			commit('SET_LOADING', true);
			const { data } = await api.get(`/product-history/${ id }`, { params });
			commit('SET_LOADING', false);

			const productHistories = data.payload;

			commit('SET_PRODUCT_HISTORIES', productHistories);
			commit('SET_PAGINATOR', data.paginator);
		},
	});
