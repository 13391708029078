import $axios from '~/plugins/axios';
import { Api, Audit, AuditLight, AuditRequest, File, GetAuditParams, GetProductTypeParams, Option, OptionRequest, PaginatorResult, ProductType, Question, QuestionExtra, QuestionRequest, QuestionType, SignatureType } from '~/api';
import { errorMessageHandeling, fetchAllPages, createIndex, assertNonNullish, duplicateQuestion, duplicateAudit } from '~/helpers';
import { v4 as uuidv4 } from 'uuid';
import { buildModule, createFiltersModule, createPaginationModule, defineSingleFilter } from '../utils';
import { t } from 'i18next';

import answers from './answers';
import categories from './categories';
import dashboard from './dashboard';
import imageArchives from './image-archives';
import items from './items';
import lazy from './lazy';
import list from './list';
import results from './results';

const api = $axios as Api;

export type OpenedAudit = Omit<AuditRequest, 'questions'> & {
	id?: number | null;
	isEditable: boolean;
	questions: OpenedAuditQuestion[];
};

export type OpenedAuditQuestion = Omit<Question, 'id' | 'type' | 'extra' | 'options' | 'images'> & {
	id?: number | null;
	type?: QuestionType | null;
	extra?: QuestionExtra | null;
	options: OpenedAuditOption[];
};

export type OpenedAuditOption = Omit<Option, 'id' | 'image'> & {
	id?: number | null;
	imageId?: number | null;
	imageFilename?: string | null;
	imageRemove?: boolean;
	_originalImage?: File | null;
};

export type DependentOption = OpenedAuditOption & {
	questionUuid: string;
	optionIndex: number;
};

export type ProductByCategory = {
	category: string;
	products: ProductType[];
};

export default buildModule()
	.withSubmodules({
		answers,
		list,
		items,
		results,
		dashboard,
		categories,
		imageArchives,
		lazy,
		filters: createFiltersModule({
			replaceRoute: true,
			filters: {
				inactive: defineSingleFilter('inactive', Boolean, false),
				categoryId: defineSingleFilter('category', Number),
				searchTerm: defineSingleFilter('searchTerm', String),
			},
		}),
		pagination: createPaginationModule({
			pageQueryKey: 'page',
			pageSizeQueryKey: 'itemsPerPage',
			itemCountGetter: 'audits/auditsTotalCount',
		}),
	})
	.withState({
		audits: [] as AuditLight[],
		auditsPaginator: null as PaginatorResult | null,
		auditSelected: null as Audit | null,
		auditsOpened: [] as OpenedAudit[],
		auditsNew: [] as OpenedAudit[],
		questionTypes: [
			{
				label: t('Jedna odpověď'),
				value: 'radiolist',
			},
			{
				label: t('Více odpovědí'),
				value: 'checkboxlist',
			},
			{
				label: t('Volná odpověď'),
				value: 'text',
			},
			{
				label: t('Číslo'),
				value: 'number',
			},
			{
				label: t('Škála'),
				value: 'range',
			},
			{
				label: t('Posuvná škála'),
				value: 'slider',
			},
			{
				label: t('Fotka'),
				value: 'image',
			},
			{
				label: t('Produkt'),
				value: 'product',
			},
			{
				label: t('E-mail'),
				value: 'email',
			},
			{
				label: t('Sériové číslo produktu'),
				value: 'serialNumber',
			},
		],
		productTypes: [] as ProductByCategory[],
		killPaginationAction: false,
	})
	.withGetters({
		audits: state => state.audits,
		auditsPaginator: state => state.auditsPaginator,
		auditsTotalCount: state => state.auditsPaginator?.totalCount ?? 0,
		auditSelected: state => state.auditSelected,
		auditsNew: state => state.auditsNew,
		auditDetail: state => (id: number) => state.auditsOpened.find(audit => audit.id === id) || null,
		questionTypes: state => state.questionTypes,
		productTypes: state => state.productTypes,
	})
	.withGetters({
		question: (_, getters) => ({ auditId, questionUuid }: {
			auditId: number;
			questionUuid: string;
		}) => {
			const thisAudit = getters.auditDetail(auditId);
			if (thisAudit == null) {
				return null;
			}

			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === questionUuid);
			return thisAudit.questions[questionIndex];
		},
		followingQuestions: (_, getters) => ({ auditId, questionUuid }: {
			auditId: number;
			questionUuid: string;
		}) => {
			const thisAudit = getters.auditDetail(auditId);
			if (thisAudit == null) {
				return [];
			}

			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === questionUuid);

			return thisAudit.questions.slice(questionIndex + 1);
		},
		questionJumpToDependentQuestions: (_, getters) => ({ auditId, questionUuid }: {
			auditId: number;
			questionUuid: string;
		}) => {
			const thisAudit = getters.auditDetail(auditId);
			return thisAudit?.questions.filter(question => question.jumpTo && question.jumpTo === questionUuid) ?? [];
		},
		questionSkipJumpToDependentQuestions: (_, getters) => ({ auditId, questionUuid }: {
			auditId: number;
			questionUuid: string;
		}) => {
			const thisAudit = getters.auditDetail(auditId);
			return thisAudit?.questions.filter(question => question.skipJumpTo && question.skipJumpTo === questionUuid) ?? [];
		},
		questionJumpToDependentOptions: (_, getters) => ({ auditId, questionUuid }: {
			auditId: number;
			questionUuid: string;
		}) => {
			const thisAudit = getters.auditDetail(auditId);
			return thisAudit?.questions.reduce((acc, question) => {
				const dependentOptions = question.options
					.filter(option => option.jumpTo != null && option.jumpTo === questionUuid)
					.map(option => ({ questionUuid: question.uuid, optionIndex: question.options.indexOf(option), ...option }));
				return [ ...acc, ...dependentOptions ];
			}, [] as DependentOption[]) ?? [];
		},
		killPaginationAction: state => state.killPaginationAction,
	})
	.withMutations({
		SET_AUDITS (state, audits: AuditLight[]) {
			state.audits = audits;
		},
		AUDIT_OPEN (state, audit: Audit) {
			const { category, roles, auditableRoles, ...rest } = audit;
			const openedAudit: OpenedAudit = {
				...rest,
				categoryId: category?.id ?? null,
				auditableRolesId: auditableRoles.map(role => role.id),
				roleId: roles.map(({ id }) => id),
			};
			state.auditsOpened = [ ...state.auditsOpened, openedAudit ];
		},
		AUDIT_ADD_NEW (state) {
			const AUDIT_DEFAULT: OpenedAudit = {
				id: null,
				name: t('Nový dotazník'),
				start: null,
				end: null,
				description: null,
				categoryId: null,
				cycle: false,
				roleId: [],
				questions: [],
				published: false,
				hasAuditableAdmin: false,
				auditableRolesId: [],
				isEditable: false,
				isScorable: false,
				scoreRate: null,
				signatureTypes: [],
			};
			state.auditsNew.push(AUDIT_DEFAULT);
			state.auditsOpened.push(AUDIT_DEFAULT);
		},
		AUDIT_ADD_DUPLICATE (state, audit: OpenedAudit) {
			state.auditsNew.push(audit);
			state.auditsOpened.push(audit);
		},
		AUDIT_DELETE (state, id: number) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === id);
			state.auditsOpened.splice(auditIndex, 1);
		},
		AUDIT_DELETE_NEW (state, index: number) {
			state.auditsNew.splice(index, 1);
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === null);
			state.auditsOpened.splice(auditIndex, 1);
		},
		AUDIT_FIELD_UPDATE (state, { id, auditUpdate }: {
			id: number;
			auditUpdate: Partial<OpenedAudit>;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === id);
			state.auditsOpened[auditIndex] = {
				...state.auditsOpened[auditIndex],
				...auditUpdate,
			};
		},
		QUESTION_ADD (state, {
			auditId,
			uuid,
			title,
			type,
			description,
			jumpTo,
			jumpToEnd,
			skipJumpTo,
			skipJumpToEnd,
			options,
			scoreSuccess,
			required,
			productTypes,
		}: {
			auditId: number;
			uuid: string;
			title: string;
			type: QuestionType | null;
			description: string | null;
			jumpTo: string | null;
			jumpToEnd: boolean;
			skipJumpTo: string | null;
			skipJumpToEnd: boolean;
			options: Option[];
			scoreSuccess: number | null;
			required: boolean;
			productTypes: ProductType[];
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			state.auditsOpened[auditIndex].questions.push({ uuid, title, type, description, jumpTo, jumpToEnd, skipJumpTo, skipJumpToEnd, options, scoreSuccess, required, productTypes });
		},
		QUESTION_ADD_DUPLICATE (state, { auditId, question }: {
			auditId: number;
			question: OpenedAuditQuestion;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			state.auditsOpened[auditIndex].questions.push(question);
		},
		QUESTION_SORT (state, { auditId, questions }: {
			auditId: number | null;
			questions: OpenedAuditQuestion[];
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			state.auditsOpened[auditIndex].questions = questions;
		},
		QUESTION_DELETE (state, { auditId, uuid }: {
			auditId: number;
			uuid: string;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === uuid);
			thisAudit.questions.splice(questionIndex, 1);
		},
		QUESTION_FIELD_UPDATE (state, { auditId, questionUuid, questionUpdate }: {
			auditId: number | null;
			questionUuid: string;
			questionUpdate: Partial<OpenedAuditQuestion>;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === questionUuid);
			thisAudit.questions[questionIndex] = {
				...thisAudit.questions[questionIndex],
				...questionUpdate,
			};
		},
		QUESTION_EXTRA_UPDATE (state, { auditId, questionUuid, extraUpdate }: {
			auditId: number;
			questionUuid: string;
			extraUpdate: QuestionExtra;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === questionUuid);
			thisAudit.questions[questionIndex].extra = {
				...thisAudit.questions[questionIndex].extra,
				...extraUpdate,
			};
		},
		QUESTION_OPTION_ADD (state, { auditId, questionUuid, option }: {
			auditId: number;
			questionUuid: string;
			option: Option;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === questionUuid);
			thisAudit.questions[questionIndex].options.push(option);
		},
		QUESTION_OPTION_DELETE (state, { auditId, questionUuid, optionIndex }: {
			auditId: number;
			questionUuid: string;
			optionIndex: number;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === questionUuid);
			thisAudit.questions[questionIndex].options.splice(optionIndex, 1);
		},
		QUESTION_OPTION_UPDATE (state, { auditId, questionUuid, optionIndex, optionUpdate }: {
			auditId: number;
			questionUuid: string;
			optionIndex: number;
			optionUpdate: Option;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === questionUuid);
			thisAudit.questions[questionIndex].options[optionIndex] = {
				...thisAudit.questions[questionIndex].options[optionIndex],
				...optionUpdate,
			};
		},
		QUESTION_OPTIONS_SORT (state, { auditId, questionUuid, options }: {
			auditId: number;
			questionUuid: string;
			options: Option[];
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			const questionIndex = thisAudit.questions.findIndex(question => question.uuid === questionUuid);
			thisAudit.questions[questionIndex].options = [ ...options ];
		},
		SET_SELECTED_AUDIT (state, audit: Audit) {
			state.auditSelected = audit;
		},
		AUDITS_PAGINATION (state, paginator: PaginatorResult) {
			state.auditsPaginator = paginator;
		},
		KILL_PAGINATION_ACTION (state, value: boolean) {
			state.killPaginationAction = value;
		},
		SET_PRODUCT_TYPES (state, productTypes: ProductType[]) {
			const productsByCategory = createIndex(productTypes, item => item.productCategory.name);
			state.productTypes = Object.entries(productsByCategory).map(([ category, products ]) => ({ category, products }));
		},
		SIGNATURE_ADD (state, { auditId, signatureUuid }: {
			auditId: number | null;
			signatureUuid: string;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			thisAudit.signatureTypes = [ ...thisAudit.signatureTypes, {
				name: t('Nový podpis'),
				uuid: signatureUuid,
			} ];
		},
		SIGNATURE_DELETE (state, { auditId, signatureUuid }: {
			auditId: number | null;
			signatureUuid: SignatureType['uuid'];
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			thisAudit.signatureTypes = thisAudit.signatureTypes.filter(signature => signature.uuid !== signatureUuid);
		},
		SIGNATURE_UPDATE_FIELD (state, { auditId, signatureUuid, signatureUpdate }: {
			auditId: number | null;
			signatureUuid: SignatureType['uuid'];
			signatureUpdate: Partial<SignatureType>;
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			const signatureIndex = thisAudit.signatureTypes.findIndex(signature => signature.uuid === signatureUuid);
			thisAudit.signatureTypes[signatureIndex] = {
				...thisAudit.signatureTypes[signatureIndex],
				...signatureUpdate,
			};
		},
		SIGNATURE_SORT (state, { auditId, signatures }: {
			auditId: number | null;
			signatures: SignatureType[];
		}) {
			const auditIndex = state.auditsOpened.findIndex(audit => audit.id === auditId);
			const thisAudit = state.auditsOpened[auditIndex];
			thisAudit.signatureTypes = [ ...signatures ];
		},
	})
	.withActions({
		getAudits ({ commit }, params: GetAuditParams = {}) {
			return api.get('/audit', { params }).then(resp => {
				commit('SET_AUDITS', resp.data.payload);
				commit('AUDITS_PAGINATION', resp.data.paginator);
			});
		},
		getAudit ({ commit }, id: number) {
			return api.get(`/audit/${ id }`, { params: { inactive: true, forMe: false } }).then(resp => {
				commit('AUDIT_OPEN', resp.data.payload);
				commit('SET_SELECTED_AUDIT', resp.data.payload);
			});
		},
	})
	.withActions({
		addAudit ({ dispatch }, { audit, inactive, params = {} }: {
			audit: OpenedAudit;
			inactive: boolean;
			params: GetAuditParams;
		}) {
			const questions: QuestionRequest[] = audit.questions.map(({
				productTypes,
				id,
				type,
				extra,
				options,
				...rest
			}) => ({
				productTypesId: !productTypes || productTypes.length === 0 ? null : productTypes.map(p => p.id),
				id: id ?? null,
				type: type ?? 'text',
				extra: extra ?? null,
				options: options as unknown as OptionRequest[] ?? null,
				images: [],
				...rest,
			}));
			const payload: AuditRequest = { ...audit, questions: [ ...questions ] };

			return api.post('/audit', payload).then(resp => {
				dispatch('getAudits', { inactive, ...params });

				new TempMessage({
					html: `<p>${ t('Dotazník byl přidán.') }</p>`,
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>${ t('Dotazník se nepodařilo uložit.') } ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
				throw new Error(err);
			});
		},
		duplicateAudit ({ commit }, audit: OpenedAudit) {
			assertNonNullish(audit.id);
			commit('AUDIT_ADD_DUPLICATE', duplicateAudit(audit));
		},
		updateAudit ({ commit, dispatch }, { audit, inactive, params = {} }: { audit: OpenedAudit; inactive: boolean; params: GetAuditParams }) {
			const questions: QuestionRequest[] = audit.questions.map(({
				productTypes,
				id,
				type,
				extra,
				options,
				...rest
			}) => ({
				productTypesId: !productTypes || productTypes.length === 0 ? null : productTypes.map(p => p.id),
				id: id ?? null,
				type: type ?? 'text',
				extra: extra ?? null,
				options: options as unknown as OptionRequest[] ?? null,
				images: [],
				...rest,
			}));
			const payload: AuditRequest = { ...audit, questions: [ ...questions ] };

			if (audit.id) {
				return api.put(`/audit/${ audit.id }`, payload).then(resp => {
					commit('AUDIT_DELETE', resp.data.payload.id);
					dispatch('getAudits', { inactive, ...params });

					new TempMessage({
						html: `<p>${ t('Dotazník byl upraven.') }</p>`,
						type: 'success',
					});

					return resp;
				}).catch((err) => {
					new TempMessage({
						html: `<p>${ t('Dotazník se nepodařilo uložit.') } ${ errorMessageHandeling(err.response.data.code) }</p>`,
						type: 'error',
					});
				});
			}
		},

		async patchAudit ({ commit }, { id, categoryId }: {
			id: number;
			categoryId: number | null;
		}) {
			const { data } = await api.patch(`/audit/${ id }`, { categoryId });
			commit('AUDIT_OPEN', data.payload);
		},

		deleteAudit (_, auditId: number) {
			return api.delete(`/audit/${ auditId }`).then(resp => {
				new TempMessage({
					html: `<p>${ t('Dotazník byl odebrán.') }</p>`,
					type: 'success',
				});

				return resp;
			}).catch((err) => {
				new TempMessage({
					html: `<p>${ t('Dotazník se nepodařilo odebrat.') } ${ errorMessageHandeling(err.response.data.code) }</p>`,
					type: 'error',
				});
			});
		},
		createQuestion ({ commit }, { auditId, title }: {
			auditId: number;
			title?: string;
		}) {
			commit('QUESTION_ADD', {
				auditId,
				uuid: uuidv4(),
				type: null,
				title: title || t('Nová otázka'),
				description: null,
				jumpTo: null,
				jumpToEnd: false,
				skipJumpTo: null,
				skipJumpToEnd: false,
				options: [],
				scoreSuccess: null,
				required: true,
				productTypes: [],
			});
		},
		duplicateQuestion ({ commit }, { auditId, question }: {
			auditId: number;
			question: OpenedAuditQuestion;
		}) {
			try {
				commit('QUESTION_ADD_DUPLICATE', { auditId, question: duplicateQuestion(question, { resetJumpTo: true, titlePrefix: 'Kopie - ' }) });
				new TempMessage({
					html: `<p>${ t('Otázka byla zduplikována.') }</p>`,
					type: 'success',
					delay: 3000,
				});
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Při duplikování otázky nastala chyba.') }</p><p>${ err }</p>`,
					type: 'error',
				});
			}

		},
		async getProductCategories ({ commit }) {
			try {
				const productTypes = await fetchAllPages<'/product-type', GetProductTypeParams>('/product-type', { isPromo: true })<ProductType>(api);
				commit('SET_PRODUCT_TYPES', productTypes);
			}
			catch (err) {
				new TempMessage({
					html: `<p>${ t('Při načítání kategorií nastala chyba.') }</p><p>${ err }</p>`,
					type: 'error',
				});
			}
		},
	});
