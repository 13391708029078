import $axios from '~/plugins/axios';

import { Api, ProtocolItem } from '~/api';
import { buildModule } from '../utils';
import { Dict } from '~/helpers';

const api = $axios as Api;

export default buildModule()
	.withState({
		storedProtocolItems: {} as Dict<ProtocolItem>,
	})
	.withGetters({
		byId: state => (protocolItemId: number): ProtocolItem | null => (
			state.storedProtocolItems[protocolItemId] ?? null
		),
	})
	.withMutations({
		STORE_PROTOCOL_ITEM (state, protocolItem: ProtocolItem) {
			state.storedProtocolItems = {
				...state.storedProtocolItems,
				[protocolItem.id]: protocolItem,
			};
		},
	})
	.withActions({
		async get ({ commit }, id: number) {
			const { data } = await api.get(`/protocol-item/${ id }`);
			commit('STORE_PROTOCOL_ITEM', data.payload);
		},
	});
